import { useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

function App() {
  const baseUrl = "https://inailcheckin-server.nailsbylr.ca/api/employees/";

  const [availableEmployees, setAvailableEmployees] = useState([]);
  const [ocupiedEmployees, setOcupiedEmployees] = useState([]);

  function calTimePast(dateTimeString) {
    const specificDateTime = new Date(dateTimeString.replace(/-/g, "/"));
    const currentDateTime = new Date();

    const differenceInMillis = currentDateTime - specificDateTime;
    const minutesAgo = Math.round(differenceInMillis / (1000 * 60));
    const hoursAgo = Math.round(differenceInMillis / (1000 * 60 * 60));

    return `${minutesAgo} minutes ago`;
  }
  //create a func to fetch available employees
  const NotifyStaffBtn = async (id) => {
    try {
      const formData = {
        employee_id: id,
      };

      await axios
        .post(baseUrl + "notify_staff_turn", formData)
        .then((response) => {
          //send request to reload data
          if (response.data.code === 200) {
            console.log("success", response);
          } else {
            alert("fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAvailableEmployees = async () => {
    try {
      const response = await axios.get(baseUrl + "available_employees_turn");
      if (response) {
        setAvailableEmployees(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  //create a func to fetch ocupied one
  const fetchOcupiedEmployees = async () => {
    try {
      const response = await axios.get(baseUrl + "ocupied_employees_turn");
      if (response) {
        setOcupiedEmployees(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //create a func to update employee status
  const setEmployeeStatus = async (id, status) => {
    //console.log('Employee id: ' + id + ' and code: ' + status)

    try {
      const formData = {
        employee_id: id,
        employee_status: status,
      };

      await axios
        .post(baseUrl + "set_employee_turn_status", formData)
        .then((response) => {
          //send request to reload data
          if (response.data.code === 200) {
            console.log("success");
          } else {
            alert("fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  //put those array in useEffect to track
  useEffect(() => {
    fetchAvailableEmployees();
  }, [availableEmployees]);

  useEffect(() => {
    fetchOcupiedEmployees();
  }, [ocupiedEmployees]);

  return (
    <div className="container my-3">
      <div className="card">
        <div className="card-header">
          <h1>iNail Staff Turn</h1>
        </div>
        <div className="card-body">
          <div className="container-fluid">
            <div className="staff_turn_wrapper">
              <div className="staff_turn_block">
                <h2>Available</h2>
                {availableEmployees.length > 0 ? (
                  <ul className="staff_turn_list_wrapper">
                    {availableEmployees.map((employee, index) => (
                      <li className="staff_turn_list_item" key={index}>
                        <div className="btn-group">
                          {employee.notifier_token ? (
                            <button
                              onClick={() => NotifyStaffBtn(employee.id)}
                              className={`bell-btn ${
                                employee.is_notified ? "disabled" : ""
                              }`}
                            >
                              <NotificationsActiveIcon />
                            </button>
                          ) : (
                            ""
                          )}
                          <button
                            onClick={() => setEmployeeStatus(employee.id, 0)}
                            data-id={employee.id}
                            data-code={0}
                          >
                            {employee.staff_name}
                            <span className="notified-time">
                              {employee.notified_on
                                ? `Notified ${calTimePast(
                                    employee.notified_on
                                  )}`
                                : ""}
                            </span>
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="alert alert-warning" role="alert">
                    No staff is available
                  </div>
                )}
                <small style={{ display: "block" }}>
                  Click to remove staff
                </small>
                <small style={{ display: "block" }}>
                  Click Bell icon to send notification to the staff
                </small>
              </div>
              <div className="staff_turn_block">
                <h2>Staff List</h2>
                {ocupiedEmployees.length > 0 ? (
                  <ul className="staff_turn_list_wrapper">
                    {ocupiedEmployees.map((employee, index) => (
                      <li className="staff_turn_list_item" key={index}>
                        <div className="btn-group">
                          <button
                            onClick={() => setEmployeeStatus(employee.id, 1)}
                            data-id={employee.id}
                            data-code={1}
                          >
                            {employee.staff_name}
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="alert alert-warning" role="alert">
                    No staff is ocupied
                  </div>
                )}
                <small>Click to add staff to available status</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="text-center py-3">
        <p className="text-muted">
          Version 1.0 - By <a href="https://lilcasoft.ca">Lilcasoft.ca</a>
        </p>
      </footer>
    </div>
  );
}

export default App;
